import React from 'react'
import styled from "styled-components"
import { PhoneIcon, GetQuoteIcon } from "../Icons"
import PrimaryIconButton from "../Button/PrimaryIconButton"
import DefaultButton from "../Button/DefaultButton"
import BreakpointUp from "../Media/BreakpointUp"
import BreakpointDown from "../Media/BreakpointDown"

const CardInfoHorizontal = styled.div`
    display: flex;
    flex-wrap: wrap;
    background-color:#fff;
    box-shadow: 0px 3px 20px rgba(14, 20, 28, 0.2);
    border: 1px solid #E3E3E3;
    position:relative;
    padding:30px 20px;
    ${BreakpointUp.sm`
        padding:30px 40px;
    `}
    &:after, &:before{
        content: '';
        height: 0;
        width: 0;
        position: absolute;       
    }
    &:after{
        border-top: 44px solid #6A6A6A;
        border-left: 44px solid transparent;
        top: 100%;
        left: 0;
    }
    &:before{
        border-top: 44px solid #6A6A6A;
        border-right: 44px solid transparent;
        top: 100%;
        right: 0;
    }
    
`
const CardInfo = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content:space-between;
    margin-right: -15px;
    margin-left: -15px;
    ${BreakpointUp.lg`
        margin-right: -25px;
        margin-left: -25px;
    `}
 
`
const CardInfoTitle = styled.div`
    color: #211F28;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 700;
    margin-bottom:5px;
    font-size: 26px;
    line-height: 30px;
    @media (min-width: 992px) {
        font-size: 34px;
        line-height: 44px;
    }
`
const CardInfoText = styled.p`
    font-size: 16px;
    line-height: 26px;
    @media (min-width: 1200px) {
        font-size:18px;
        line-height:30px;
    }
`

const CardLeftCol = styled.div`   
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    ${BreakpointUp.lg`
        padding-right: 25px;
        padding-left: 25px;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    `}
`
const CardRightCol = styled.div`
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    ${BreakpointUp.lg`
        flex: 0 0 auto;
        width: auto;
        max-width: none;
        padding-right: 25px;
        padding-left: 25px;
    `}
`
const ButtonToolbar = styled.div`
    display:flex;
    align-items:center;
    margin:0 -10px;
    ${BreakpointDown.sm`
        flex-direction: column;
        a{
            width: 100%;
            + a{
                margin-top:15px;
            }
        }
        .btn{
            width:100%;
        }
    `}
    ${BreakpointUp.xl`            
        margin:0 -20px;
    `}     
    a{
        padding:0 10px;
        ${BreakpointUp.xl`    
            padding:0 20px;
        `}     
    } 
`
const RequestQuote = ({ intl, openQuoteModal}) => {   
	return(
        <CardInfoHorizontal className="card">
            <CardInfo>      
                <CardLeftCol>
                    <CardInfoTitle>{intl.formatMessage({ id: "Request a quote online" })}</CardInfoTitle>
                    <CardInfoText>{intl.formatMessage({ id: "Global Carport is more than happy to help out customers by ensuring that all your needs have been met. For more information on our metal structures, contact our sales representative to get started on your project today!" })}</CardInfoText>
                </CardLeftCol>  
                <CardRightCol>
                    <ButtonToolbar>
                        <a href="tel:8009187432" className="btn-lg"><PrimaryIconButton icon={<PhoneIcon />} text="(800) 918-7432"  /></a>
                        <button onClick={openQuoteModal} className="btn-lg"><DefaultButton icon={<GetQuoteIcon />} text={intl.formatMessage({ id: "Get a quote"})} /></button>
                    </ButtonToolbar>
                </CardRightCol>  
            </CardInfo>    
        </CardInfoHorizontal>
	)	
}

export default RequestQuote