import React, { useState } from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  BreadCrumb,
  Section,
  SectionTitle,
  MarkdownContent,
} from "../components/Section"
import { BulletIcon } from "../components/Icons"
import TopHeader from "../components/TopHeader"
import RequestQuote from "../components/RequestQuote"
import BreakpointUp from "../components/Media/BreakpointUp"
import { v4 } from "uuid"
import QuotePopup from "../components/QuotePopup"

const CardWhite = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  position: relative;
  box-shadow: 0px 3px 20px rgba(14, 20, 28, 0.2);
  color: #211f28;
  padding: 30px;
  margin-bottom: 20px;
`
const CardWhiteTitle = styled.h2`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0;
  ${BreakpointUp.md`    
        font-size: 20px;
        line-height: 26px;
    `}
  ${BreakpointUp.xl`
        font-size: 26px;
        line-height: 30px;
    `}
`
const CardIcon = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  margin-bottom: 0;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
  .gatsby-image-wrapper {
    width: 72px;
  }
`
const CardWhiteBody = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
`
const CardWhiteLead = styled.div``
const CardHorizontal = styled.div`
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
  position: relative;
  align-items: center;
  margin-bottom: 30px;
  ${BreakpointUp.lg`
        margin-bottom:90px;
    `}
`
const CardHorizontalItem = styled.div`
  position: relative;
  width: 100%;
  ${BreakpointUp.lg`
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    `}
  &:nth-child(2) {
    .card {
      background-color: #0f0f11;
      color: #fff;
      border: none;
      box-shadow: none;
      h2 {
        color: #fff;
      }
      &:after,
      &:before {
        display: none;
        ${BreakpointUp.lg`       
                    dislay:block;
                    background-color:#0F0F11;
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    height: 20px;
                    z-index: 1;
                `}
      }
      &:before {
        top: -18px;
      }
      &:after {
        bottom: -18px;
      }
    }
  }
`
const GridRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  ${BreakpointUp.lg`
        margin-right: 0;
        margin-left: 0;
    `}
`
const GridLeftColumn = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.md`	
        flex: 0 0 50%;
        max-width: 50%;
    `}
  ${BreakpointUp.lg`	     
        padding:0 40px 0 30px;
    `}
`

const CardInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  ${BreakpointUp.md`
        height: 100%;
        margin-bottom:0;
    `}
`
const CardInfoBody = styled.div`
  padding: 15px 30px;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  width: 100%;
  max-width: 480px;
  box-shadow: 0px 3px 20px rgba(14, 20, 28, 0.2);
  min-height: 166px;
  position: relative;
  z-index: 1;
  ${BreakpointUp.lg`
        margin-top: -15%;
        margin-left: -30px;
    `}
`
const CardInfoFigure = styled.figure`
  position: relative;
  width: 100%;
  margin: 0;
  background: #fff;
  box-shadow: 15px 15px 0px 0px rgba(0, 0, 0, 0.05);

  & .gatsby-image-wrapper {
    height: 100%;
  }
`
const CardInfoTitle = styled.h3`
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 0;
  ${BreakpointUp.lg`
        font-size: 34px;
        line-height: 44px;
    `}
`
const CardInfoLead = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 28px;
  ${BreakpointUp.lg`
        font-size:18px;
        line-height:30px;
    `}
`

const TimelineWrap = styled.div`
  position: relative;
  ${BreakpointUp.md`        
        display: flex;
        justify-content: space-around;     
        &:after{
            content: "";
            position: absolute;        
            top: 50%;
            transform:translateY(-50%);
            background-image: linear-gradient(to right,#CFCFCF 50%,rgb(255,255,255) 0%);
            background-size: 10px 2px;
            width: 100%;
            height: 2px;
            background-position: 0px 0px;
            background-repeat: repeat-x;
        }
    `}
`
const TimelineRow = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  ${BreakpointUp.md` 
        justify-content: space-between;
    `}
  &:first-child {
    .col {
      + .col {
        &:after,
        &:before {
          display: none;
        }
      }
    }
  }
  .col {
    ${BreakpointUp.md`
            margin-top: -45px;
            &:nth-child(odd) {
                margin-bottom: -45px;
                margin-top:0;
                .block{
                    flex-direction: column-reverse;
                }
            }
            &:nth-child(even) {
                &:after{                
                    top: 19%;
                }    
                &:before{
                    top: 22%;   
                }            
            }
            &:after, &:before{
                content:'';
                position:absolute;
                top: 0;
            }
            &:after{               
                width:22px;
                height:22px;
                border-radius:50%;
                border:1px solid #CFCFCF;
                background-color:#fff;
                top: 67%;
                left: -11px
            }
            &:before{
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #CFCFCF;
                z-index: 1;
                top: 70%;
                left: -6px
            }       
        `}
  }
  &:last-child {
    ${BreakpointUp.md`
            align-items: flex-end;
            width: 50%;
            justify-content: center;
            .col{
                margin-bottom: -30px;
                &:after{                
                    top: 0;
                    left: -50%;
                }    
                &:before{
                    top: 5px;   
                    left: calc(-50% + 5px);
                }   
            }
        `}
  }
`
const TimelineColumn = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  z-index: 1;
  position: relative;
  ${BreakpointUp.md`
        margin-top: -92px;    
        &.offset{
            margin-left:50%;
        }      
    `}
`
const TimelineLabel = styled.h3`
  display: block;
  color: #6a6a6a;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 0;
  font-weight: 600;
  @media (min-width: 992px) {
    font-size: 26px;
    line-height: 30px;
  }
`
const TimelineIcon = styled.span`
  border: 2px solid #cfcfcf;
  width: 115px;
  height: 115px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  .gatsby-image-wrapper {
    position: relative;
    z-index: 1;
    width: 100%;
  }
`
const TimelineBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

function AboutUsPage({ data, intl }) {
  const pageData = data.contentfulAboutUsPage
  const [isVisibleQuote, setQuoteVisible] = useState(false)
  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }
  return (
    <Layout>
      <SEO title={pageData.metaTitle} description={pageData.metaDescription} />
      <BreadCrumb>
        <div className="container">
          <Link to="/">
            {intl.formatMessage({ id: "Home" })} <BulletIcon />
          </Link>
          <span>{pageData.pageName}</span>
        </div>
      </BreadCrumb>
      <TopHeader
        topHeaderTitle={pageData.heroTitle}
        topHeaderText={pageData.heroDescription}
        topHeaderImg={pageData.heroImage}
      />
      <Section
        pt="0"
        pb="0"
        xpt="60px"
        xpb="0"
        top="-100px"
        bgColor="transparent"
      >
        <div className="container">
          <CardHorizontal>
            {pageData.features.map(item => (
              <CardHorizontalItem key={v4()}>
                <CardWhite className="card">
                  <CardIcon>
                    <Img
                      fluid={item.featureImage.fluid}
                      alt={item.featureImage.description}
                    />
                  </CardIcon>
                  <CardWhiteBody className="card-body">
                    <CardWhiteTitle>{item.feature}</CardWhiteTitle>
                    <CardWhiteLead>
                      {item.description.description}
                    </CardWhiteLead>
                  </CardWhiteBody>
                </CardWhite>
              </CardHorizontalItem>
            ))}
          </CardHorizontal>
          <GridRow>
            {pageData.missionVision.map(item => (
              <GridLeftColumn key={v4()}>
                <CardInfo className="card">
                  <CardInfoFigure className="card-img">
                    <Img
                      fluid={item.featureImage.fluid}
                      alt={item.featureImage.description}
                    />
                  </CardInfoFigure>
                  <CardInfoBody className="card-body">
                    <CardInfoTitle>{item.feature}</CardInfoTitle>
                    <CardInfoLead>{item.description.description}</CardInfoLead>
                  </CardInfoBody>
                </CardInfo>
              </GridLeftColumn>
            ))}
          </GridRow>
        </div>
      </Section>
      <Section pt="90px" pb="100px" xpt="60px" xpb="90px" bgColor="#F9F9F9">
        <div className="container">
          <SectionTitle>{pageData.coreValueSectionTitle}</SectionTitle>
          <TimelineWrap>
            <TimelineRow>
              <TimelineColumn className="col offset">
                <TimelineBlock className="block">
                  <TimelineLabel>{pageData.coreValues[0].title}</TimelineLabel>
                  <TimelineIcon>
                    <Img
                      fluid={pageData.coreValues[0].fluid}
                      alt={pageData.coreValues[0].description}
                    />
                  </TimelineIcon>
                </TimelineBlock>
              </TimelineColumn>
              <TimelineColumn className="col">
                <TimelineBlock className="block">
                  <TimelineLabel>{pageData.coreValues[1].title}</TimelineLabel>
                  <TimelineIcon>
                    <Img
                      fluid={pageData.coreValues[1].fluid}
                      alt={pageData.coreValues[1].description}
                    />
                  </TimelineIcon>
                </TimelineBlock>
              </TimelineColumn>
            </TimelineRow>
            <TimelineRow>
              <TimelineColumn className="col offset">
                <TimelineBlock className="block">
                  <TimelineLabel>{pageData.coreValues[2].title}</TimelineLabel>
                  <TimelineIcon>
                    <Img
                      fluid={pageData.coreValues[2].fluid}
                      alt={pageData.coreValues[2].description}
                    />
                  </TimelineIcon>
                </TimelineBlock>
              </TimelineColumn>
              <TimelineColumn className="col">
                <TimelineBlock className="block">
                  <TimelineLabel>{pageData.coreValues[3].title}</TimelineLabel>
                  <TimelineIcon>
                    <Img
                      fluid={pageData.coreValues[3].fluid}
                      alt={pageData.coreValues[3].description}
                    />
                  </TimelineIcon>
                </TimelineBlock>
              </TimelineColumn>
            </TimelineRow>
            <TimelineRow>
              <TimelineColumn className="col">
                <TimelineBlock className="block">
                  <TimelineLabel>{pageData.coreValues[4].title}</TimelineLabel>
                  <TimelineIcon>
                    <Img
                      fluid={pageData.coreValues[4].fluid}
                      alt={pageData.coreValues[4].description}
                    />
                  </TimelineIcon>
                </TimelineBlock>
              </TimelineColumn>
            </TimelineRow>
          </TimelineWrap>
        </div>
      </Section>
      <Section pt="90px" pb="190px" xpt="60px" xpb="90px" bgColor="#FFFFFF">
        <div className="container">
          <MarkdownContent>
            <div
              dangerouslySetInnerHTML={{
                __html: pageData.pageContentSection.childMarkdownRemark.html,
              }}
            />
          </MarkdownContent>
        </div>
      </Section>
      <Section
        pt="0"
        pb="90px"
        xpt="0"
        xpb="60px"
        top="-100px"
        xtop="0"
        bgColor="transparent"
        className="quote"
      >
        <div className="container">
          <RequestQuote
            intl={intl}
            openQuoteModal={() => setQuoteVisible(true)}
          />
        </div>
      </Section>
      <QuotePopup
        isVisible={isVisibleQuote}
        onClose={() => setQuoteVisible(false)}
        intl={intl}
      />
    </Layout>
  )
}

export default injectIntl(AboutUsPage)

export const pageQuery = graphql`
  query AboutUsPageQuery($locale: String) {
    contentfulAboutUsPage(node_locale: { eq: $locale }) {
      node_locale
      metaTitle
      metaDescription
      pageName
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
        description
      }
      features {
        feature
        description {
          description
        }
        featureImage {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
          description
        }
      }
      missionVision {
        feature
        description {
          description
        }
        featureImage {
          fluid(quality: 100) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
          description
        }
      }
      coreValueSectionTitle
      coreValues {
        title
        fluid(quality: 100) {
          base64
          aspectRatio
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
        description
      }
      pageContentSection {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
